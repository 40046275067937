module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-JYM72N2F41","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"atlfilmparty.com","enableWebVitalsTracking":true,"anonymize":false,"respectDNT":false},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap"},{"name":"DM Serif Display","file":"https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap"},{"name":"Merriweather","file":"https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-afpzine-js": () => import("./../../../src/pages/afpzine.js" /* webpackChunkName: "component---src-pages-afpzine-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-call-for-entries-js": () => import("./../../../src/pages/call-for-entries.js" /* webpackChunkName: "component---src-pages-call-for-entries-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-detail-template-js": () => import("./../../../src/templates/article-detail-template.js" /* webpackChunkName: "component---src-templates-article-detail-template-js" */),
  "component---src-templates-event-detail-template-js": () => import("./../../../src/templates/event-detail-template.js" /* webpackChunkName: "component---src-templates-event-detail-template-js" */)
}

